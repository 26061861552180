<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="2" class="pr-12">
          <h2>Products</h2>
        </v-col>
        <v-col cols="6" class="pl-12">
          <v-text-field
            label="Search Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <v-col cols="4" align="right">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="secondary" class="mr-2" v-on="on">
                  <v-icon small>mdi-truck-fast</v-icon>
                </v-btn>
              </template>
              <span>View Shipping</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-eow-products-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="info"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-eow-product-brands' }"
                >
                  <v-icon small>mdi-tag-text-outline</v-icon>
                </v-btn>
              </template>
              <span>View Brands</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="pink white--text"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-eow-product-sizes' }"
                >
                  <v-icon small>mdi-size-xxs</v-icon>
                </v-btn>
              </template>
              <span>View Sizes</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.productForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Product</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-eow-product-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Products</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-select
            label="Status"
            :items="statuses"
            v-model="statusSearch"
            item-text="text"
            item-value="value"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            label="Stock Status"
            :items="stockStatuses"
            item-text="text"
            item-value="value"
            v-model="stocksSearch"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <treeselect
            :multiple="true"
            :options="parentCategories"
            :flat="true"
            :isDefaultExpanded="true"
            :default-expand-level="1"
            :show-count="false"
            v-model="categoriesSearch"
            placeholder="Categories"
            :openOnClick="false"
            :closeOnSelect="false"
          />

          <!-- <v-select
            :items="parentCategories"
            label="Select Category"
            multiple
            outlined
            dense
          > -->
          <!-- <template v-slot:item="{ item }">
              <v-list-item :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template> -->

          <!-- <template v-slot:item="{ item }">
              <v-list opened>
                <v-list-item v-if="!item.children" :key="i" class="v-list-item">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ link.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-group
                  v-else
                  :key="item.name"
                  no-action
                  v-model="group"
                  :append-icon="null"
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="primary--text">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <v-list-item
                    v-for="sublink in item.children"
                    :key="sublink.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        {{ sublink.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </template> -->

          <!-- <template v-slot:prepend-item>
              <v-list opened>
                <div v-for="(link, i) in parentCategories" :key="link.id">
                  <v-list-item
                    v-if="!link.children"
                    :key="i"
                    class="v-list-item"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        fgfdgdffdgd
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-group
                    v-else
                    :key="link.name"
                    no-action
                    v-model="group"
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="primary--text">
                            {{ link.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-list-item
                      v-for="sublink in link.children"
                      :key="sublink.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="primary--text">
                          {{ sublink.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </div>
              </v-list>
            </template> -->
          <!-- </v-select> -->
        </v-col>

        <v-col cols="2">
          <v-select
            label="Brand"
            :items="brands"
            v-model="brandSearch"
            item-text="name"
            item-value="id"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="products"
        no-data-text="There are currently no products"
      >
        <template v-slot:item.categories="{ item }">
          {{ item.categories.map((c) => c.name).join(", ") }}
        </template>

        <template v-slot:item.price="{ item }">
          £{{ formatPrice(item.price) }}
        </template>

        <template v-slot:item.is_out_of_stock="{ item }">
          <v-icon color="red" v-if="item.is_out_of_stock"
            >mdi-close-circle</v-icon
          >
          <v-icon color="green" v-else>mdi-check-circle</v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                v-on="on"
                @click="$refs.tagsForm.openForm(item)"
              >
                <v-icon x-small>mdi-tag</v-icon>
              </v-btn>
            </template>
            <span>Add Tags</span>
          </v-tooltip> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-eow-products-individual',
                  params: { productId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Product</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>

            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.productForm.openForm(item.id)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteProduct(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <ProductForm ref="productForm" />
    <TagsForm ref="tagsForm" />
    <v-dialog v-model="deleteProduct.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Product</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteProduct.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteProduct"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteProduct.loading"
            @click="saveDeleteProduct"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductForm from "./components/ProductForm";
import TagsForm from "./components/TagsForm";

export default {
  components: {
    ProductForm,
    TagsForm,
  },

  data() {
    return {
      searchTerm: "",
      statusSearch: "",
      stocksSearch: "",
      categoriesSearch: [],
      brandSearch: "",
      selected: null,
      group: true,

      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Stock", value: "is_out_of_stock" },
        { text: "Price", value: "price" },
        { text: "Categories", value: "categories", sortable: false },
        { text: "Status", value: "status" },
        { text: "Brand", value: "brand.name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      selectedCategory: [],
      cascaderOptions: [
        {
          value: "electronics",
          label: "Electronics",
          children: [
            { value: "phones", label: "Phones" },
            { value: "laptops", label: "Laptops" },
          ],
        },
        {
          value: "clothing",
          label: "Clothing",
          children: [
            { value: "shirts", label: "Shirts" },
            { value: "shoes", label: "Shoes" },
          ],
        },
      ],
      stockStatuses: [
        { text: "In Stock", value: "1" },
        { text: "Out of Stock", value: "0" },
      ],
      statuses: [
        { text: "Draft", value: "draft" },
        { text: "Published", value: "published" },
      ],
      deleteProduct: {
        dialog: false,
        product: {},
        loading: false,
      },
    };
  },

  filters: {},

  computed: {
    parentCategories() {
      let categories = this.categories.filter((c) => {
        return c.is_parent === true;
      });

      categories = categories.map((category) => {
        let childExist = undefined;

        if (category.children.length > 0) {
          childExist = category.children.map((childCategory) => {
            return {
              id: childCategory.id,
              label: childCategory.name,
            };
          });
        }
        return {
          id: category.id,
          label: category.name,
          children: childExist,
        };
      });

      return categories;
    },

    categories() {
      let categories = this.$store.getters["eow/productsStore/categories"];

      return categories;
    },

    brands() {
      let brands = this.$store.getters["eow/productsStore/brands"];

      return brands;
    },

    products() {
      let products = this.$store.getters["eow/productsStore/all"];

      if (this.brandSearch !== "") {
        products = products.filter((c) => c.brand.name == this.brandSearch);
      }

      if (this.statusSearch !== "") {
        products = products.filter((c) => c.status == this.statusSearch);
      }

      if (this.categoriesSearch.length > 0) {
        products = products.filter((product) =>
          product.categories.some((category) =>
            this.categoriesSearch.includes(category.id)
          )
        );
      }

      if (this.stocksSearch !== "") {
        products = products.filter(
          (c) => c.is_out_of_stock == this.stocksSearch
        );
      }

      if (this.searchTerm !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();

          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return products;
    },
  },

  methods: {
    openDeleteProduct(product) {
      this.deleteProduct.product = product;
      this.deleteProduct.dialog = true;
    },

    resetDeleteProduct() {
      this.deleteProduct.dialog = false;
      this.deleteProduct.product = {};
      this.deleteProduct.loading = false;
    },

    saveDeleteProduct() {
      this.deleteProduct.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteProduct", {
          appId: this.$route.params.id,
          productId: this.deleteProduct.product.id,
        })
        .then(() => {
          this.resetDeleteProduct();
        })
        .catch(() => {
          this.deleteProduct.loading = false;
        });
    },
  },
};
</script>
